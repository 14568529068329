import serverLogger from "../utils/ServerLogger";

// src/services/websocketService.js
let wss = null;
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 10;
const BASE_RECONNECT_DELAY_MS = 2000;

const listeners = [];

export function addWebSocketListener(callback) {
  listeners.push(callback);
}

export function removeWebSocketListener(callback) {
  const index = listeners.indexOf(callback);
  if (index > -1) listeners.splice(index, 1);
}

function notifyListeners(data) {
  listeners.forEach((callback) => callback(data));
}

export function initializeWebSocket() {
  if (wss) return;

  const connect = () => {
   serverLogger.log('info', "Attempting to connect to WebSocket..."); // Debug: Connecting log
     wss = new WebSocket(`${process.env.REACT_APP_BACKEND_WSURL}`);

     wss.onopen = () => {
        reconnectAttempts = 0;
        //serverLogger.log("WebSocket connected"); // Debug: Connected log
     };

     wss.onmessage = (event) => {
        const data = JSON.parse(event.data);
        //serverLogger.log("Received WebSocket message:", data); // Debug: Message received log
        notifyListeners(data);
     };

     wss.onerror = (error) => {
        console.error("WebSocket error:", error);
        wss.close();
     };

     wss.onclose = () => {
        //serverLogger.log("WebSocket closed, attempting reconnect"); // Debug: Closed log
        wss = null;
        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
           reconnectAttempts++;
           setTimeout(connect, BASE_RECONNECT_DELAY_MS * reconnectAttempts);
        }
     };
  };

  connect();
}

