import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, Button, Box } from '@mui/material';

const AdCard = ({ rotationInterval = 10000 }) => {
  // Liste over annonser
  const ads = [
    {
      title: 'Start Trading with TradeNation and FlockTrade',
      image: 'https://tradenation.ck-cdn.com/tn/serve/?cid=404036',
      link: 'https://go.tradenation.com/visit/?bta=36156',
      description: 'Join the leading trading platform and start growing your investments today.',
    },
    {
      title: 'Trade directly on TradingView',
      image: 'https://tradenation.ck-cdn.com/tn/serve/?cid=404043',
      link: 'https://go.tradenation.com/visit/?bta=36156',
      description: 'Improve your trading skills with the best platform that have the lowest FIXED spreads',
    },
  ];

  // State for å holde oversikt over hvilken annonse som vises
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    // Sett opp en timer for å bytte annonser etter `rotationInterval`
    const adTimer = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length); // Gå til neste annonse
    }, rotationInterval);

    return () => clearInterval(adTimer); // Rydd opp når komponenten demonteres
  }, [ads.length, rotationInterval]);

  // Gjeldende annonse
  const currentAd = ads[currentAdIndex];

  return (
    <Card
    sx={{
    maxWidth: 1000,
      margin: '10px auto',
      boxShadow: 3,
      borderRadius: 2,
      overflow: 'hidden',
      position: 'relative', // Nødvendig for å posisjonere "ads"-etiketten
      transition: 'opacity 0.5s ease-in-out', // Jevn overgang mellom annonser
    }}
  >
    <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)', // Halvtransparent svart bakgrunn
      color: 'white',
      padding: '2px 8px',
      fontSize: '12px',
      fontWeight: 'bold',
      borderBottomRightRadius: '4px', // Gir en avrunding i hjørnet
    }}
  >
    Ads
  </Box>
      {/* Annonse Bilde */}
      <CardMedia
        component="img"
        image={currentAd.image}
        alt={currentAd.title}
        sx={{
          objectFit: 'cover',
          width: { xs: '100%', sm: '100%', md: '100%' }, // Fyll bredden til kortet
          height: { xs: '50%', md: '90px'}, // Oppretthold bildets proporsjoner
        }}
      />
      {/* Annonse Innhold */}
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          {currentAd.title}
        </Typography>
        {currentAd.description && (
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
            {currentAd.description}
          </Typography>
        )}
      </CardContent>
      {/* Call to Action */}
      <CardActions>
        <Box sx={{ margin: '0 auto' }}>
          <Button
            size="small"
            href={currentAd.link}
            target="_blank"
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'none',
              borderRadius: 3,
            }}
          >
            Learn More
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default AdCard;
