import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import GoogleAnalytics from './components/GoogleAnalytics';
import Home from './pages/Home';
import Sentiment from './pages/Sentiment';
import Dashboard from './pages/Dashboard';
import Header from './components/Header';
import Footer from './components/Footer';
import NewsDeskPage from './pages/Newsdesk/NewsDeskPage';
import SignUp from './pages/SignUp';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/LogIn';
import AuthRedirect from './components/AuthRedirect';
import ForexNewsCalendar from './pages/Calendar'
import NewsletterPopup from './components/NewsLetterPopup'; // Importer popup-komponenten
import SignalStats from './pages/Signals/SignalDashboard';
import CookieConsentBanner from './components/CookieConsentBanner';

function AppLayout({ children }) {
  const location = useLocation();
  const isNewsDeskPage = location.pathname === '/news-desk';

  return (
    <>
      {!isNewsDeskPage && <Header />}
      {children}
      {!isNewsDeskPage && <Footer />}
    </>
  );
}

function App() {
  return (
  <>
        {/* NewsletterPopup legges her for å være aktiv på alle sider */}
        <GoogleAnalytics /> 
      <NewsletterPopup />
      <CookieConsentBanner />
    <AppLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sentiment" element={<Sentiment />} />
        <Route path="/calendar" element={<ForexNewsCalendar /> } />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signals" element={<SignalStats />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute requiredPermissions={['flocktrader']}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/news-desk" element={<NewsDeskPage />} />
        <Route path="*" element={<AuthRedirect />} />
      </Routes>
    </AppLayout>
  </>
  );
}

export default App;
