import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Badge,
  CircularProgress,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { useUserData } from '../auth/UserData';
import AdminPanel from '../components/AdminPanel';
import WebServerStats from './Dashboard/webServerStats';
import AdminStatistics from '../components/AdminStatistics';
import { useNavigate } from 'react-router-dom';
import ChangelogPopup from '../components/ChangelogPopup';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation(); // Oversettelsesfunksjon
  const { user, isLoading } = useUserData();
  const navigate = useNavigate();
  
  const CURRENT_VERSION = '1.0.2';
  const CHANGELOG = `

   <ul>
    <li>
      Newsdesk: Added sound, you can choose sound for new incomming news.
    </li>
    <li>
      Global: Added multilanguage for Norwegian and English
    </li>
  </ul>

  <ul>
    <li>
      Bug fixed: ForexNews on newsdesk, autoscroll down to nextevent. and live updates.
    </li>
    <li>
    New style: sentiment got an makeover, added timeframe on the graph
    </li>
  </ul>

  <ul>
    <li>New feature: Dashboard redesign.</li>
    <li>Added Weekly and monthly Stats navigation.</li>
    <li>Improved performance and fixed bugs.</li>
     
    <li>New feature: Sentiment Analysis.</li>
    <li>Added Forex Calendar for better planning.</li>
    <li>Improved NewsDesk:
      <ul>
        <li>Added Ignore Source feature.</li>
        <li>Time Zone adjustment functionality.</li>
      </ul>
    </li>
    <li>Enhanced Signal Statistics Overview.</li>
  </ul>

`;

const [showChangelog, setShowChangelog] = useState(false);
const [showBadge, setShowBadge] = useState(false);

useEffect(() => {
  const storedVersion = localStorage.getItem('latestVersion');
  if (storedVersion !== CURRENT_VERSION) {
    setShowChangelog(true);
    setShowBadge(true);
  }
}, []);

const handleOpenChangelog = () => {
  setShowChangelog(true);
  setShowBadge(false);
  localStorage.setItem('latestVersion', CURRENT_VERSION);
};

const handleCloseChangelog = () => {
  setShowChangelog(false);
  localStorage.setItem('latestVersion', CURRENT_VERSION);
};

const hasPermission = (permission) => user?.permissions?.includes(permission);

if (isLoading) {
  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    </Container>
  );
}

if (!user || !user.permissions) {
  return (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '50vh', textAlign: 'center' }}>
      <Typography variant="h4" color="error" gutterBottom>
        {t('dashboard.accessDenied.title')}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {t('dashboard.accessDenied.message')}
      </Typography>
    </Container>
  );
}

return (
  <Container maxWidth="lg">
    {/* Changelog Popup */}
    {showChangelog && (
      <ChangelogPopup
        version={CURRENT_VERSION}
        changelog={CHANGELOG}
        onClose={handleCloseChangelog}
      />
    )}

    {/* Dashboard Header */}
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
      <Typography variant="h4">{t('dashboard.title')}</Typography>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {t('dashboard.version', { version: CURRENT_VERSION })}
        </Typography>
        <Badge color="error" variant="dot" invisible={!showBadge}>
          <Button size="small" onClick={handleOpenChangelog}>
            {t('dashboard.whatsNew')}
          </Button>
        </Badge>
      </Box>
    </Box>

    {/* Admin Section */}
    {hasPermission('flocktech') && <AdminStatistics />}

    {/* Trader Tools */}
    <Box mt={4}>
      <Grid container spacing={3}>
        {hasPermission('flocktrader') && (
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">{t('dashboard.traderTools.title')}</Typography>
                <Typography>{t('dashboard.traderTools.description')}</Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 2, mr: 2 }}
                  onClick={() => {
                    const popup = window.open(
                      '/news-desk',
                      'NewsDesk',
                      'width=1500,height=600,scrollbars=no,resizable=yes'
                    );
                    if (!popup) {
                      alert('Popup blocked! Please allow popups for this website.');
                    }
                  }}
                >
                  {t('dashboard.traderTools.newsDesk')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                  onClick={() => navigate('/signals')}
                >
                  {t('dashboard.traderTools.signalStatistics')}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        )}
        {hasPermission('flockadmin') && (
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">{t('dashboard.adminTools.title')}</Typography>
                <AdminPanel />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>

    {/* Server & Bot Statistics */}
    <WebServerStats />
  </Container>
);
};

export default Dashboard;