import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, TextField, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import newsletterImage from '../assets/newsletter-trading.png';
import ServerLogger from '../utils/ServerLogger';
import { useTranslation } from 'react-i18next';

const NewsletterPopup = () => {
  const { t } = useTranslation(); // Henter oversettelser
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const lastAction = localStorage.getItem('newsletter_action');
    const lastActionTime = localStorage.getItem('newsletter_action_time');

    if (lastAction === 'subscribed') return;

    const now = new Date().getTime();
    const timeSinceLastAction = lastActionTime ? now - parseInt(lastActionTime, 10) : Infinity;

    if (lastAction === 'dismissed' && timeSinceLastAction < 1200000) {
      return; // Vent 20 minutter før popup-en vises igjen
    }

    const timer = setTimeout(() => {
      setOpen(true);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  const handleSubscribe = async () => {
    if (!name || !email) {
      setError(t('newsletter.errorRequired'));
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/api/newsletter/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error || t('newsletter.errorGeneric'));
        return;
      }

      localStorage.setItem('newsletter_action', 'subscribed');
      setOpen(false);
    } catch (error) {
      ServerLogger.error('Error subscribing:', error);
      setError(t('newsletter.errorGeneric'));
    }
  };

  const handleDismiss = () => {
    localStorage.setItem('newsletter_action', 'dismissed');
    localStorage.setItem('newsletter_action_time', new Date().getTime());
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleDismiss}
      aria-labelledby="newsletter-modal-title"
      aria-describedby="newsletter-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          width: '90%',
          maxWidth: 500,
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleDismiss}
        >
          <CloseIcon />
        </IconButton>

        <Stack spacing={2} alignItems="center">
          <Box
            component="img"
            src={newsletterImage}
            alt="Newsletter illustration"
            sx={{
              width: '100%',
              maxWidth: 400,
              height: 'auto',
              borderRadius: 4,
              mb: 2,
            }}
          />

          <Typography
            id="newsletter-modal-title"
            variant="h5"
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            {t('newsletter.title')}
          </Typography>

          <Typography
            id="newsletter-modal-description"
            sx={{ textAlign: 'center', color: 'text.secondary', mb: 2 }}
          >
            {t('newsletter.description')}
          </Typography>

          {error && (
            <Typography variant="body2" color="error" sx={{ textAlign: 'center' }}>
              {error}
            </Typography>
          )}

          <TextField
            label={t('newsletter.nameLabel')}
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label={t('newsletter.emailLabel')}
            type="email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubscribe}
              startIcon={<EmailIcon />}
            >
              {t('newsletter.subscribe')}
            </Button>
            <Button variant="outlined" color="error" onClick={handleDismiss}>
              {t('newsletter.close')}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default NewsletterPopup;
