import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faDiscord, faFacebook, faTiktok, faSnapchat } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <AppBar
      position="static"
      color="primary"
      sx={{
        marginTop: 4,
        top: 'auto',
        bottom: 0,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 2,
        }}
      >
        {/* Footer Text */}
        <Typography variant="body2" align="center" sx={{ mb: { xs: 1, md: 0 } }}>
          {t('footer.copyright', { year: new Date().getFullYear() })}
        </Typography>

        {/* Social Media Links */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <IconButton
            aria-label="Instagram"
            color="inherit"
            onClick={() => window.open('https://www.instagram.com/flocktrade/', '_blank')}
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </IconButton>

          <IconButton
            aria-label="Discord"
            color="inherit"
            onClick={() => window.open('https://discord.com/invite/sQjPufbufK', '_blank')}
          >
            <FontAwesomeIcon icon={faDiscord} size="lg" />
          </IconButton>

          <IconButton
            aria-label="TikTok"
            color="inherit"
            onClick={() => window.open('https://tiktok.com/@flocktrade', '_blank')}
          >
            <FontAwesomeIcon icon={faTiktok} size="lg" />
          </IconButton>

          <IconButton
            aria-label="Facebook"
            color="inherit"
            onClick={() => window.open('https://facebook.com/flocktradecom', '_blank')}
          >
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </IconButton>

          <IconButton
            aria-label="Snapchat"
            color="inherit"
            onClick={() => window.open('https://www.snapchat.com/add/FlockTrade.com', '_blank')}
          >
            <FontAwesomeIcon icon={faSnapchat} size="lg" />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
