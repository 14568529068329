import React, { useState } from "react";
import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  // Mapp språk til landskode for flagg
  const languageToCountryCode = {
    en: "us", // Engelsk -> USA flagg
    no: "no", // Norsk -> Norge flagg
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleClick}
        aria-controls="language-menu"
        aria-haspopup="true"
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(languageToCountryCode).map((lng) => (
          <MenuItem key={lng} onClick={() => changeLanguage(lng)}>
            <Box
              component="img"
              src={`https://flagcdn.com/w40/${languageToCountryCode[lng]}.png`}
              alt={lng}
              sx={{ width: 24, height: 16, mr: 1 }}
            />
            {lng === "en" ? "English" : "Norsk"}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSwitcher;
