import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import serverLogger from '../utils/ServerLogger';

const UserDataContext = createContext();

export const useUserData = () => useContext(UserDataContext);

export const UserDataProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [authInProgress, setAuthInProgress] = useState(false);
  const [hasFetchedUser, setHasFetchedUser] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    if (hasFetchedUser) return; // Prevent multiple fetches
    try {
      serverLogger.log('info', 'Fetching user data... [/src/auth/UserData.js]'); // Debug log
      setIsLoading(true);
      const response = await fetch(`${BACKEND_URL}/auth/me`, {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        serverLogger.log('info', 'Fetched user data:', data); // Log fetched data
        setUser(data);
      } else {
        serverLogger.log('info', 'User not authenticated:', response.status);
        setUser(null);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
    } finally {
      setIsLoading(false);
      setHasFetchedUser(true); // Mark fetch as complete
    }
  }, [hasFetchedUser, BACKEND_URL]);

  // Initial authentication check
  useEffect(() => {
    const initAuth = async () => {
      try {
        serverLogger.log('info', 'Initializing authentication check... [/src/auth/UserData.js]');
        const response = await fetch(`${BACKEND_URL}/auth/status`, {
          credentials: 'include',
        });
        const { authenticated } = await response.json();
        if (authenticated) {
          await fetchUserData();
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error during authentication check:', error);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    initAuth();
  }, [fetchUserData, BACKEND_URL]);

  return (
    <UserDataContext.Provider
      value={{
        user,
        isLoading: isLoading || authInProgress,
        setUser,
        refreshUserData: fetchUserData,
        startAuth: () => setAuthInProgress(true),
        endAuth: () => setAuthInProgress(false),
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
