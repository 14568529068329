import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// Initialiser i18next
i18n
  .use(HttpApi) // Last oversettelser fra backend
  .use(LanguageDetector) // Detekter brukerens språk
  .use(initReactI18next) // Integrasjon med React
  .init({
    supportedLngs: ["en", "no"], // Språkene du ønsker å støtte
    fallbackLng: "en", // Standard språk hvis ingen treff
    detection: {
      order: ["queryString", "cookie", "localStorage", "navigator"], // Prioritet for språkdeteksjon
      caches: ["cookie"], // Lagre språkpreferanser i cookie
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Last oversettelsesfiler
    },
    react: {
      useSuspense: true, // Suspense for å vente på oversettelser
    },
  });

export default i18n;
