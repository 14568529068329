import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

const GoogleAnalytics = () => {
  const analyticsConsent = Cookies.get("analytics");

  useEffect(() => {
    if (analyticsConsent === "enabled") {
      console.log("Google Analytics initialized.");
    } else {
      console.log("Google Analytics not initialized due to lack of consent.");
    }
  }, [analyticsConsent]);

  return (
    <>
      {analyticsConsent === "enabled" && (
        <Helmet>
          {/* Legg til Google Analytics-skriptet i head-delen */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-3W0LMNR756"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', 'G-3W0LMNR756');
            `}
          </script>
        </Helmet>
      )}
    </>
  );
};

export default GoogleAnalytics;
