import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Collapse,
  Chip,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  CheckCircle,
  ErrorOutline,
  RestartAlt,
  Stop,
} from '@mui/icons-material';
import { useUserData } from '../../auth/UserData';
import axios from 'axios';

const WebServerStats = () => {
  const { user, refreshUserData } = useUserData();
  const [serverStatsVisible, setServerStatsVisible] = useState(false);
  const [serverStats, setServerStats] = useState(null);
  const [webServerStats, setWebServerStats] = useState(null);
  const [botStatus, setBotStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const hasPermission = (permission) => user?.permissions?.includes(permission);

  const toggleServerStats = () => setServerStatsVisible((prev) => !prev);

  const fetchAllStats = useCallback(async () => {
    if (!user || !hasPermission('flocktech')) return;

    setLoading(true);
    try {
      const [serverRes, webServerRes, botRes] = await Promise.all([
        axios.get(`${BACKEND_URL}/api/stats/server-stats`, { withCredentials: true }),
        axios.get(`${BACKEND_URL}/api/stats/web-server`, { withCredentials: true }),
        axios.get(`${BACKEND_URL}/api/bots/status`, { withCredentials: true }),
      ]);

      setServerStats(serverRes.data);
      setWebServerStats(webServerRes.data);
      setBotStatus(botRes.data);
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoading(false);
    }
  }, [BACKEND_URL, user]);

  const handleBotAction = async (botName, action) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/bots/${botName}/${action}`,
        null,
        { withCredentials: true }
      );
      setSnackbar({
        open: true,
        message: response.data.message,
        severity: 'success',
      });
      fetchAllStats();
    } catch (error) {
      console.error(`Error performing ${action} on bot ${botName}:`, error);
      setSnackbar({
        open: true,
        message: `Failed to ${action} bot ${botName}`,
        severity: 'error',
      });
    }
  };

  const openConfirmDialog = (botName, action) => {
    setConfirmAction({ botName, action });
  };

  const confirmBotAction = async () => {
    if (confirmAction) {
      setActionLoading(true);
      try {
        await handleBotAction(confirmAction.botName, confirmAction.action);
      } catch (error) {
        console.error('Error during bot action:', error);
      } finally {
        setActionLoading(false);
        setConfirmAction(null);
      }
    }
  };

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  useEffect(() => {
    if (serverStatsVisible) {
      fetchAllStats();
      const intervalId = setInterval(fetchAllStats, 15000); // Update every 15 seconds
      return () => clearInterval(intervalId);
    }
  }, [serverStatsVisible, fetchAllStats]);

  useEffect(() => {
    refreshUserData?.();
  }, [refreshUserData]);

  if (!hasPermission('flocktech')) {
    return null; // Render nothing if the user lacks permission
  }

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Server & Bot Statistics
        </Typography>
        <Button
          variant="contained"
          onClick={toggleServerStats}
          endIcon={serverStatsVisible ? <ExpandLess /> : <ExpandMore />}
        >
          {serverStatsVisible ? 'Hide Stats' : 'Show Stats'}
        </Button>
        <Collapse in={serverStatsVisible} timeout="auto" unmountOnExit>
          <Box mt={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={3}>
                {/* Web Server Stats */}
                {webServerStats && (
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Web Server</Typography>
                        <Typography variant="body2">
                          Uptime: {webServerStats.uptime || 'N/A'}
                        </Typography>
                        <Typography variant="body2">
                          CPU Usage: {webServerStats.cpuUsage || 'N/A'}%
                        </Typography>
                        <Typography variant="body2">
                          RSS Memory: {webServerStats.memoryUsage?.rss || 'N/A'} MB
                        </Typography>
                        <Typography variant="body2">
                          Heap Used: {webServerStats.memoryUsage?.heapUsed || 'N/A'} MB
                        </Typography>
                        <Typography variant="body2">
                          Total Requests: {webServerStats.requestCount || 'N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {/* Linux Server Stats */}
                {serverStats && (
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Linux Server</Typography>
                        <Typography variant="body2">
                          CPU Usage: {serverStats.cpuUsage || 'N/A'}%
                        </Typography>
                        <Typography variant="body2">
                          Memory Usage: {serverStats.memoryUsage || 'N/A'}% (
                          {serverStats.usedMemory || 'N/A'} GB / {serverStats.totalMemory || 'N/A'} GB)
                        </Typography>
                        {serverStats.diskUsage?.map((disk, index) => (
                          <Box key={index} mt={2}>
                            <Typography variant="body2">
                              Disk {index + 1}: {disk.fs || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              Size: {disk.size || 'N/A'} GB, Used: {disk.used || 'N/A'} GB (
                              {disk.usage || 'N/A'}%)
                            </Typography>
                          </Box>
                        ))}
                        <Typography variant="body2">
                          Total Processes: {serverStats.processCount || 'N/A'}
                        </Typography>
                        <Typography variant="body2">
                          Uptime: {serverStats.uptime || 'N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {/* Bot Stats */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Bots
                  </Typography>
                  <Grid container spacing={3}>
                    {botStatus.map((bot, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6">{bot.name}</Typography>
                            <Chip
                              label={bot.status}
                              color={bot.status === 'online' ? 'success' : 'error'}
                              icon={bot.status === 'online' ? <CheckCircle /> : <ErrorOutline />}
                            />
                            <Typography variant="body2">
                              CPU: {bot.cpu || 'N/A'} | Memory: {bot.memory || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              Uptime: {bot.uptime || 'N/A'}
                            </Typography>
                            <Button
                              variant="outlined"
                              startIcon={<RestartAlt />}
                              onClick={() => openConfirmDialog(bot.name, 'restart')}
                              sx={{ mt: 2, mr: 1 }}
                            >
                              Restart
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<Stop />}
                              color="error"
                              onClick={() => openConfirmDialog(bot.name, 'stop')}
                              sx={{ mt: 2 }}
                            >
                              Stop
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </Collapse>
      </Box>
      {/* Confirm Dialog */}
      <Dialog open={!!confirmAction} onClose={() => setConfirmAction(null)}>
        <DialogTitle sx={{ color: 'error.main' }}>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to <b>{confirmAction?.action.toUpperCase()}</b> the bot{' '}
            <b>{confirmAction?.botName}</b>? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmAction(null)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmBotAction}
            color="error"
            disabled={actionLoading}
            startIcon={actionLoading ? <CircularProgress size={20} /> : null}
          >
            {actionLoading ? 'Processing...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default WebServerStats;
