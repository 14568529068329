import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserData } from '../auth/UserData';
import ServerLogger from '../utils/ServerLogger';

const AuthRedirect = () => {
  const { user } = useUserData();
  const location = useLocation();
ServerLogger.log('info', user );
ServerLogger.log('info', location)

  // Redirect only if the user is authenticated and is on a blank route
  if (user && location.pathname === '/') {
    return <Navigate to="/dashboard" />;
  }

  // Display a 404 page for any undefined route
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for does not exist.</p>
    </div>
  );
};

export default AuthRedirect;
