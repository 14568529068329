import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const ChangelogPopup = ({ version, changelog, onClose }) => (
  <Dialog open onClose={onClose}>
    <DialogTitle>What's New in {version}</DialogTitle>
    <DialogContent>
    <Typography dangerouslySetInnerHTML={{ __html: changelog }} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export default ChangelogPopup;
