class ServerLogger {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;
  }

  async log(level, message, metadata = null) {
    const logEntry = {
      timestamp: new Date().toISOString(),
      level,
      message: message || 'No message provided', // Standardmelding hvis message er tom
      metadata: metadata || {}, // Tomt objekt hvis metadata er null
    };

    //console.log('Sending log entry:', logEntry);

    try {
      const response = await fetch(`${this.apiUrl}/api/logs`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(logEntry),
      });

      if (!response.ok) {
        console.error('Failed to send log. Response:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Failed to send log to server:', error);
    }
  }
}

module.exports = new ServerLogger(process.env.REACT_APP_BACKEND_URL);
