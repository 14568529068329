import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, ButtonGroup } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import 'chart.js/auto';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const Sentiment = () => {
  const { t } = useTranslation(); // Henter oversettelser
  const theme = useTheme();
  const [sentimentData, setSentimentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historicalData, setHistoricalData] = useState({});
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [timeframe, setTimeframe] = useState('24h');
  const excludedAssets = ['BRENT', 'USOIL', 'DOLLARINDEX']; // Eiendeler som skal ignoreres

  useEffect(() => {
    const fetchSentimentData = async () => {
      try {
        const response = await fetch('https://flocktrade.com/api/sentiment');
        if (!response.ok) throw new Error('Failed to fetch sentiment data');
        const data = await response.json();

        const filteredData = data.filter((entry) => !excludedAssets.includes(entry.asset));
        setSentimentData(filteredData);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSentimentData();
  }, []);

  const fetchHistoricalData = async (asset, timeframe) => {
    try {
      const response = await fetch(`https://flocktrade.com/api/sentiment/history/${asset}?timeframe=${timeframe}`);
      if (!response.ok) throw new Error('Failed to fetch historical data');
      const data = await response.json();
  
      setHistoricalData((prev) => ({
        ...prev,
        [asset]: {
          ...(prev[asset] || {}),
          [timeframe]: data,
        },
      }));
      setSelectedAsset(asset);
    } catch (error) {
      console.error('Error fetching historical sentiment data:', error);
    }
  };

  const formatDateAndTime = (dateString, timeString) => {
    const dateTime = DateTime.fromISO(`${dateString}T${timeString}`);
    return dateTime.toFormat('dd MMM yyyy, HH:mm');
  };

  const generateGraphData = (data) => ({
    labels: data.map((entry) => {
      const dateTime = DateTime.fromISO(entry.timestamp); // Konverter timestamp til Luxon DateTime
      return dateTime.toFormat('dd MMM, HH:mm'); // Eks: "06 Dec, 15:45"
    }),
    datasets: [
      {
        label: `Long Sentiment for ${selectedAsset}`,
        data: data.map((entry) => entry.avg_long),
        borderColor: theme.palette.mode === 'dark' ? 'white' : 'blue',
        borderWidth: 2,
        fill: false,
        pointRadius: 0, // Fjern punktene på grafen
      },
    ],
  });

  const handleTimeframeChange = (asset, newTimeframe) => {
    setTimeframe(newTimeframe);
    fetchHistoricalData(asset, newTimeframe);
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: 'background.default', color: 'text.primary', minHeight: '100vh' }}>
      {/* Header Section */}
      <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        {t('sentiment.title')}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
        {t('sentiment.description')}
        </Typography>
      </Box>

      {/* Why Sentiment Analysis? */}
      <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>
      {t('sentiment.whyMatters')}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: 'center', p: 2, backgroundColor: 'background.paper', boxShadow: 3 }}>
            <TrendingUpIcon fontSize="large" color="primary" />
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {t('sentiment.trackTrends')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{t('sentiment.trackTrendsDesc')}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: 'center', p: 2, backgroundColor: 'background.paper', boxShadow: 3 }}>
            <AssessmentIcon fontSize="large" color="primary" />
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {t('sentiment.informedDecisions')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('sentiment.informedDecisionsDesc')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: 'center', p: 2, backgroundColor: 'background.paper', boxShadow: 3 }}>
            <ShowChartIcon fontSize="large" color="primary" />
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {t('sentiment.reactToMovements')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('sentiment.reactToMovementsDesc')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Live Sentiment Data */}
      <Box sx={{ marginTop: '40px', marginBottom: '40px' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>
        {t('sentiment.liveData')}
        </Typography>
        {loading ? (
          <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px' }}>
          {t('sentiment.loading')}
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {sentimentData.map((entry) => (
              <Grid item xs={12} key={entry.asset}>
              <Card sx={{ backgroundColor: 'background.paper', boxShadow: 3, padding: '10px' }}>
              <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {entry.asset.replace('TECH100', 'Nasdaq(TECH100)').replace('US30', 'Dow Jones(US30)')}
              </Typography>
              <Typography variant="caption" sx={{ marginBottom: '10px', color: 'text.secondary' }}>
              {t('sentiment.lastUpdated')}: {formatDateAndTime(entry.date, entry.time)}
            </Typography>
            </Box>
                {/* Progress Bar for Long and Short */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                  {t('sentiment.long')}: {entry.long_percentage.toFixed(1)}%
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#f44336' }}>
                  {t('sentiment.short')}: {entry.short_percentage.toFixed(1)}%
                  </Typography>
                </Box>
                <Box
                sx={{
                  position: 'relative',
                  marginBottom: '10px',
                  borderRadius: '5px',
                  overflow: 'hidden', // Hindrer at fylte deler strekker seg utenfor
                  backgroundColor: '#f44336', // Rød bakgrunn for SHORT
                  height: '20px', // Høyde på progress-baren
                }}
              >
                {/* Grønn del for LONG */}
                <Box
                  sx={{
                    position: 'absolute',
                    left: '0',
                    width: `${entry.long_percentage}%`,
                    height: '100%',
                    backgroundColor: '#4caf50', // Grønn farge for LONG
                  }}
                />
                {/* Prosentandel for LONG */}
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: `${entry.long_percentage / 2}%`, // Midt i den grønne delen
                    transform: 'translate(-50%, -50%)',
                    fontWeight: 'bold',
                    color: '#fff',
                    fontSize: '12px',
                  }}
                >
                  {entry.long_percentage.toFixed(1)}%
                </Typography>
                {/* Prosentandel for SHORT */}
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: `${entry.short_percentage / 2}%`, // Midt i den røde delen
                    transform: 'translate(50%, -50%)', // Flytt mot venstre for sentrering
                    fontWeight: 'bold',
                    color: '#fff',
                    fontSize: '12px',
                  }}
                >
                  {entry.short_percentage.toFixed(1)}%
                </Typography>
              </Box>
              
                <Button
                variant="outlined"
                color="primary"
                sx={{ marginTop: '10px' }}
                onClick={() => {
                  if (selectedAsset === entry.asset) {
                    setSelectedAsset(null); // Skjul grafen
                  } else {
                    fetchHistoricalData(entry.asset, timeframe); // Hent data for valgt eiendel og tidsramme
                  }
                }}
              >
                {selectedAsset === entry.asset ? t('sentiment.hideGraph') : t('sentiment.showGraph')}
              </Button>
                {selectedAsset === entry.asset && historicalData[entry.asset]?.[timeframe] && (
                  <Box sx={{ marginTop: '20px' }}>
                  <ButtonGroup sx={{ marginBottom: '10px' }}>
                  <Button onClick={() => handleTimeframeChange(entry.asset, '24h')}>{t('sentiment.timeframes.24h')}</Button>
                  <Button onClick={() => handleTimeframeChange(entry.asset, '1w')}> {t('sentiment.timeframes.1w')}</Button>
                  <Button onClick={() => handleTimeframeChange(entry.asset, '1m')}> {t('sentiment.timeframes.1m')}</Button>
                </ButtonGroup>
                    <Box
                      sx={{
                        width: '100%',
                        maxWidth: '100%',
                        height: '400px',
                        margin: '0 auto',
                      }}
                    >
                      <Line
                        data={generateGraphData(historicalData[entry.asset][timeframe])}
                        options={{
                          maintainAspectRatio: false,
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>
            
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Call to Action Section */}
      <Box
        sx={{
          padding: '40px',
          backgroundColor: 'primary.main',
          color: 'white',
          textAlign: 'center',
          borderRadius: '8px',
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
            {t('sentiment.ctaTitle')}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
        {t('sentiment.ctaDesc')}
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{ padding: '10px 30px', borderRadius: '8px', fontWeight: 'bold' }}
          onClick={() => window.location.href = '/signup'}
        >
        {t('sentiment.ctaButton')}
        </Button>
      </Box>
    </Box>
  );
};

export default Sentiment;
