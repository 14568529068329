import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@mui/material';
import { VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const IgnoreCurrenciesPopover = ({ ignoredCurrencies, handleCurrencyToggle }) => {
  const { t } = useTranslation(); // Hent t-funksjonen fra i18n
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'ignore-currencies-popover' : undefined;

  const currencies = [
    "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "NOK", "NZD"
  ];

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleOpen}
        color="inherit"
        sx={{ ml: 1 }}
      >
        <Typography variant="caption"           sx={{
            fontSize: '1.0rem', // Juster tekststørrelse til mindre
            color: 'text.secondary', // Subtil tekstfarge
            mr: 0.5, // Mellomrom mellom tekst og ikon
          }}>
          {t('ignoreCurrenciesPopover.buttonLabel')}
        </Typography>
        <VisibilityOff fontSize="small" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold', px: 2 }}>
        {t('ignoreCurrenciesPopover.title')}
        </Typography>
        <Box sx={{ px: 2, pb: 2 }}>
          <List dense>
            {currencies.map((currency) => (
              <ListItem key={currency} disablePadding>
                <ListItemText
                  primary={currency}
                  primaryTypographyProps={{
                    color: ignoredCurrencies.includes(currency) ? 'error' : 'text.secondary',
                    fontSize: '0.8rem', // Mindre tekst
                    fontWeight: ignoredCurrencies.includes(currency) ? 'bold' : 'normal',
                  }}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={ignoredCurrencies.includes(currency)}
                    onChange={() => handleCurrencyToggle(currency)}
                    size="small"
                    color={ignoredCurrencies.includes(currency) ? 'error' : 'primary'} // Rød når aktivert
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default IgnoreCurrenciesPopover;
