import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@mui/material';
import { VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const IgnoreSourcesPopover = ({ ignoredSources, handleSourceToggle }) => {
  const { t } = useTranslation(); // Hent t-funksjonen fra i18n
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'ignore-sources-popover' : undefined;

  return (
    <>
      {/* Icon Button to trigger the Popover */}
      <IconButton
        aria-describedby={id}
        onClick={handleOpen}
        color="inherit"
        sx={{
          ml: 1, // Reduce margin-left
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption" // Mindre tekststørrelse
          sx={{
            fontSize: '1.0rem', // Juster tekststørrelse til mindre
            color: 'text.secondary', // Subtil tekstfarge
            mr: 0.5, // Mellomrom mellom tekst og ikon
          }}
        >
        {t('ignoreSourcesPopover.buttonLabel')}
        </Typography>
        <VisibilityOff sx={{ fontSize: '1rem' }} /> {/* Reduser ikonstørrelsen */}
      </IconButton>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: 250, // Mindre bredde
            padding: 1, // Mindre padding
            borderRadius: 1,
            boxShadow: 3,
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ mb: 1, fontSize: '0.9rem', fontWeight: 'bold' }} // Mindre overskrift
        >
        {t('ignoreSourcesPopover.title')}
        </Typography>
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            p: 1,
            bgcolor: 'background.paper',
            boxShadow: 1,
          }}
        >
          <List dense sx={{ p: 0 }}>
            {[
              "FT-News:",
              "FT-Economic:",
              "FT-Options:",
              "FT-Fedspeak:",
              "FT-Filings:",
              "FT-Technical:",
              "FT-Team:",
              "FT-News1:",
              "FT-News2:",
              "FT-Breaking:",
            ].map((source) => (
              <ListItem
                key={source}
                disablePadding
                sx={{
                  py: 0.5, // Redusert padding
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
              >
                <ListItemText
                  primary={source}
                  primaryTypographyProps={{
                    color: ignoredSources.includes(source) ? 'error' : 'text.secondary',
                    fontSize: '0.8rem', // Mindre tekst
                    fontWeight: ignoredSources.includes(source) ? 'bold' : 'normal',
                  }}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={ignoredSources.includes(source)} // Aktivert hvis kilden skal ignoreres
                    onChange={() => handleSourceToggle(source)} // Oppdaterer listen over ignorerte kilder
                    color={ignoredSources.includes(source) ? 'error' : 'primary'} // Rød når aktivert
                    size="small" // Mindre bryter
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default IgnoreSourcesPopover;
