import React, { useEffect } from 'react';
import { Container, Box, Typography, Button, CircularProgress } from '@mui/material';
import { useUserData } from '../auth/UserData';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation(); // Oversettelsesfunksjon
  const { user, isLoading, startAuth, endAuth, refreshUserData } = useUserData();
  const navigate = useNavigate();
  const DISCORD_AUTH_URL = `${process.env.REACT_APP_BACKEND_URL}/auth/discord`;

  // Redirect to dashboard if the user is already logged in
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const handleLogin = () => {
    startAuth(); // Indicate that authentication is in progress

    const width = 500;
    const height = 700;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const popup = window.open(
      DISCORD_AUTH_URL,
      'Discord Login',
      `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=yes,status=no`
    );

    if (!popup) {
      alert(t('login.popupBlocked')); // Oversatt popup-advarsel
      endAuth(); // End authentication process if popup is blocked
    } else {
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
          endAuth(); // Mark the authentication process as completed
          refreshUserData(); // Refresh user data after the popup closes
        }
      }, 500);
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h4" gutterBottom>
          {t('login.welcome')} {/* Oversatt velkomsttekst */}
        </Typography>
        <Typography variant="body1" color="textSecondary" textAlign="center" mb={4}>
          {t('login.description')} {/* Oversatt beskrivelse */}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleLogin}>
          {t('login.button')} {/* Oversatt knappetekst */}
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
