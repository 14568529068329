// /src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserData } from '../auth/UserData';
import rolePermissions from '../middleware/rolePermissions';
import serverLogger from '../utils/ServerLogger';

const ProtectedRoute = ({ children, requiredPermissions }) => {
  const { user, isLoading } = useUserData();

  if (isLoading) {
    // Vis en lasteskjerm mens brukerdata oppdateres
    return <div>Loading...</div>;
  }

  if (!user || !user.roles) {
    serverLogger.log('info', 'User not authenticated or roles missing:', user);
    return <Navigate to="/login" replace />;
  }

  // Beregn brukerens tillatelser basert på roller
  const userPermissions = new Set();
  user.roles.forEach((roleId) => {
    if (rolePermissions[roleId]) {
      rolePermissions[roleId].forEach((perm) => userPermissions.add(perm));
    }
  });

  // Sjekk om brukeren har nødvendige tillatelser
  const hasPermission = requiredPermissions.some((perm) => userPermissions.has(perm));

  if (!hasPermission) {
    serverLogger.log('info', 'User lacks required permissions:', { userPermissions, requiredPermissions });
    return <Navigate to="/unauthorized" replace />;
  }

  // Returner children hvis alt er validert
  return children;
};

export default ProtectedRoute;
