import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  useMediaQuery,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Menu as SettingsIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import ForexNews from './ForexNews';
import NewsDesk from './NewsDesk';
import Sentiment from './Sentiment';
import IgnoreSourcesPopover from './IgnoreSourcesPopover';
import IgnoreCurrenciesPopover from './IgnoreCurrenciesPopover'; 
import { useTranslation } from 'react-i18next';

const NewsDeskPage = () => {
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = useState(false);
  const [timezone, setTimezone] = useState(() => {
    return localStorage.getItem('timezone') || 'local';
  });
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showForexNews, setShowForexNews] = useState(true);
  const [showSentiment, setShowSentiment] = useState(true);
  const [ignoredSources, setIgnoredSources] = useState(
    JSON.parse(localStorage.getItem('ignoredSources')) || []
  );
  const [ignoredCurrencies, setIgnoredCurrencies] = useState(
    JSON.parse(localStorage.getItem('ignoredCurrencies')) || []
  );
  const [selectedSound, setSelectedSound] = useState(
    localStorage.getItem('selectedSound') || 'none'
  ); // Ny tilstand for valgt lyd

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  useEffect(() => {
    setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  useEffect(() => {
    localStorage.setItem('ignoredSources', JSON.stringify(ignoredSources));
    localStorage.setItem('ignoredCurrencies', JSON.stringify(ignoredCurrencies));
    localStorage.setItem('selectedSound', selectedSound);
  }, [ignoredSources, ignoredCurrencies, selectedSound]);

  const handleSoundChange = (event) => {
    const newSound = event.target.value;
    setSelectedSound(newSound);
    localStorage.setItem('selectedSound', newSound);
  
    // Spill av den valgte lyden for forhåndsvisning
    if (newSound !== 'none') {
      const previewAudio = new Audio(`/sounds/${newSound}.mp3`);
      previewAudio.play().catch((err) => {
        console.error('Failed to play preview sound:', err);
      });
    }
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: { main: '#007FFF' },
      background: {
        default: darkMode ? '#0A1929' : '#f5f5f5',
        paper: darkMode ? '#112240' : '#ffffff',
      },
      text: {
        primary: darkMode ? '#ffffff' : '#000000',
        secondary: darkMode ? '#b0bec5' : '#757575',
      },
    },
  });

  const toggleDarkMode = () => setDarkMode(!darkMode);
  const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);

  const handleSourceToggle = (source) => {
    const updatedIgnoredSources = ignoredSources.includes(source)
      ? ignoredSources.filter((s) => s !== source)
      : [...ignoredSources, source];
    setIgnoredSources(updatedIgnoredSources);
    localStorage.setItem('ignoredSources', JSON.stringify(updatedIgnoredSources));
  };

  const handleCurrencyToggle = (currency) => {
    const updatedIgnoredCurrencies = ignoredCurrencies.includes(currency)
      ? ignoredCurrencies.filter((c) => c !== currency) // Fjern valuta fra listen
      : [...ignoredCurrencies, currency]; // Legg til valuta i listen
    setIgnoredCurrencies(updatedIgnoredCurrencies);
  };

  const handleTimezoneToggle = () => {
    const newTimezone = timezone === 'local' ? 'us' : 'local';
    setTimezone(newTimezone);
    localStorage.setItem('timezone', newTimezone);
  };

  const handleTimezoneChange = (newTimezone) => {
    
    setTimezone(newTimezone);
    localStorage.setItem('timezone', newTimezone);
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  const TimezoneSwitch = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 16,
    padding: '2px 4px',
    width: 80,
    cursor: 'pointer',
    '& > span': {
      flex: 1,
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 'bold',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
    '& .active': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#007FFF',
      color: '#fff',
      borderRadius: 16,
      padding: '4px 8px',
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <AppBar position="sticky" color="primary">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {t('newsDesk.appTitle')}
            </Typography>

              {/* Dark/Light Mode */}
            <MaterialUISwitch
            sx={{ m: 1 }}
            checked={darkMode}
            onChange={toggleDarkMode}
          />

            <IconButton color="inherit" onClick={handleMenuOpen}>
              <SettingsIcon />
            </IconButton>
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
            
  {/* Tidssone-innstilling */}
  <MenuItem>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <Typography variant="body1">{t('newsDesk.setTime')}</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {timezone === 'local' ? t('newsDesk.timezoneCET') : t('newsDesk.timezoneEST')}
      </Typography>
      <Switch
        checked={timezone === 'local'}
        onChange={() => handleTimezoneChange(timezone === 'local' ? 'us' : 'local')}
      />
    </Box>
  </Box>
</MenuItem>
<MenuItem>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
    <Typography variant="body1">{t('newsDesk.showForexCalendar')}</Typography>
    <Switch
      checked={showForexNews}
      onChange={() => setShowForexNews((prev) => !prev)}
    />
  </Box>
</MenuItem>

<MenuItem>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
    <Typography variant="body1">{t('newsDesk.showSentiment')}</Typography>
    <Switch
      checked={showSentiment}
      onChange={() => setShowSentiment((prev) => !prev)}
    />
  </Box>
</MenuItem>

<MenuItem>
<FormControl fullWidth>
  <InputLabel>{t('newsDesk.soundSelection')}</InputLabel>
  <Select
    value={selectedSound}
    onChange={handleSoundChange}
    sx={{ width: 200 }}
  >
    <MenuItem value="none">{t('newsDesk.noSound')}</MenuItem>
    <MenuItem value="seatbelt">{t('newsDesk.seatbelt')}</MenuItem>
    <MenuItem value="alert">{t('newsDesk.alert')}</MenuItem>
  </Select>
</FormControl>
</MenuItem>

    {/* Ignore Sources Popover */}
    <IgnoreSourcesPopover
      ignoredSources={ignoredSources}
      handleSourceToggle={handleSourceToggle}
    />

    <IgnoreCurrenciesPopover
    ignoredCurrencies={ignoredCurrencies}
    handleCurrencyToggle={handleCurrencyToggle}
  />
            </Menu>
          </Toolbar>
        </AppBar>

        <Grid container spacing={2} justifyContent={showForexNews || showSentiment ? 'flex-start' : 'center'}>
          {showForexNews && (
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  height: 'calc(100vh - 100px)',
                  overflowY: 'hidden',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: '8px',
                  padding: '10px',
                  boxShadow: 3,
                  position: 'relative',
                  '&:hover': {
                    overflowY: 'auto',
                  },
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: darkMode ? '#007FFF' : '#757575',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: darkMode ? '#005BBB' : '#555555',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: darkMode ? '#0A1929' : '#f5f5f5',
                  },
                }}
              >
                <ForexNews timezone={timezone} ignoredCurrencies={ignoredCurrencies}/>
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={showForexNews && showSentiment ? 5 : showForexNews || showSentiment ? 8 : 10}
          >
            <Box
              sx={{
                height: 'calc(100vh - 100px)',
                overflowY: 'hidden',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '8px',
                padding: '10px',
                boxShadow: 3,
                position: 'relative',
                '&:hover': {
                  overflowY: 'auto',
                },
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: darkMode ? '#007FFF' : '#757575',
                  borderRadius: '8px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: darkMode ? '#005BBB' : '#555555',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: darkMode ? '#0A1929' : '#f5f5f5',
                },
              }}
            >
            
              <NewsDesk ignoredSources={ignoredSources} timezone={timezone} selectedSound={selectedSound} />
            </Box>
          </Grid>

          {showSentiment && (
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  height: 'calc(100vh - 100px)',
                  overflowY: 'hidden',
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: '8px',
                  padding: '10px',
                  boxShadow: 3,
                  position: 'relative',
                  '&:hover': {
                    overflowY: 'auto',
                  },
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: darkMode ? '#007FFF' : '#757575',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: darkMode ? '#005BBB' : '#555555',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: darkMode ? '#0A1929' : '#f5f5f5',
                  },
                }}
              >
                <Sentiment />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default NewsDeskPage;
