import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import { Public } from "@mui/icons-material";
import { DateTime } from "luxon";

const ForexNews = ({ ignoredCurrencies }) => {
  const [news, setNews] = useState([]);
  const [impactFilter, setImpactFilter] = useState("");
  const [dataReady, setDataReady] = useState(false); // Ny tilstand for å spore når dataene er klare
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(false); // Auto-scroll status
  const nextEventRef = useRef(null);
  const [mockNow, setMockNow] = useState(DateTime.fromISO("2024-12-06T20:29:45", { zone: "UTC" })); // Start klokka på 20:29
  const [hasScrolled, setHasScrolled] = useState(false); // Forhindrer flere scrolls
  
  const currencyToCountryCode = {
    USD: "us",
    EUR: "eu",
    JPY: "jp",
    GBP: "gb",
    AUD: "au",
    CAD: "ca",
    CHF: "ch",
    CNY: "cn",
    NOK: "no",
    NZD: "nz",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setMockNow((prev) => prev.plus({ seconds: 1 })); // Øk med 1 sekund
    }, 1000);
    return () => clearInterval(interval); // Rydd opp når komponenten unmountes
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/news/forex-calendar?filter=month`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setNews(data);
        setDataReady(true); // Indikerer at dataene er klare
      } catch (error) {
        console.error("Error fetching forex news:", error);
      }
    };

    fetchData();
  }, []);

  const nextEventId = useMemo(() => {
    if (!news || news.length === 0) return null;
  
    const filteredNews = news
      .filter((item) => 
        !ignoredCurrencies.includes(item.currency) &&
        (!impactFilter || item.impact.toLowerCase() === impactFilter.toLowerCase())
      )
      .find((item) => {
        const eventDateTime = DateTime.fromISO(`${item.event_date}T${item.time}`, { zone: "UTC" });
        return eventDateTime > DateTime.now();
      });
  
    return filteredNews?.event_id || null;
  }, [news, ignoredCurrencies, impactFilter]);

  useEffect(() => {
    // Scroll kun én gang per lasting av data
    if (!hasScrolled && nextEventRef.current) {
      nextEventRef.current.scrollIntoView({ behavior: "smooth" });
      setHasScrolled(true); // Marker at vi har scrollet
      //console.log("Scrolled to next event on initial load");
    }
  }, [news, hasScrolled]); // Trigges når dataene (news) er lastet inn

 // Scroll kontinuerlig hvis autoscroll er aktivert
  useEffect(() => {
    if (autoScrollEnabled && nextEventRef.current) {
      nextEventRef.current.scrollIntoView({ behavior: "smooth" });
      console.log("Auto-scroll to nextEventRef");
    }
  }, [mockNow, autoScrollEnabled]); // Kjør hvis mockNow oppdateres og autoscroll er aktivert

  const formatTime = (date, time) => {
    if (!date || !time) return { date: "Invalid Date", time: "Invalid Time" };

    const textTimes = ["All Day", "Tentative"];
    const dayTextMatch = time.match(/^Day \d+$/);

    if (textTimes.includes(time) || dayTextMatch) {
      return { date, time };
    }

    const timeParts = time.match(/^(\d{1,2}):(\d{2})(am|pm)?$/i);
    if (!timeParts) return { date: "Invalid Date", time: "Invalid Time" };

    let [_, hours, minutes, period] = timeParts;
    hours = parseInt(hours, 10);

    if (period) {
      if (period.toLowerCase() === "pm" && hours < 12) {
        hours += 12;
      } else if (period.toLowerCase() === "am" && hours === 12) {
        hours = 0;
      }
    }

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}`;
    const dateTime = `${date}T${formattedTime}:00`;

    const sourceTimeZone = "UTC";
    const targetTimeZone =
      localStorage.getItem("timezone") === "us"
        ? "America/New_York"
        : "Europe/Oslo";

    try {
      const parsedDate = DateTime.fromISO(dateTime, { zone: sourceTimeZone });
      const convertedDate = parsedDate.setZone(targetTimeZone);

      if (!convertedDate.isValid) return { date: "Invalid Date", time: "Invalid Time" };

      const formattedDate = convertedDate.toFormat("yyyy-MM-dd");
      const formattedTime =
        localStorage.getItem("timezone") === "us"
          ? convertedDate.toFormat("hh:mm a")
          : convertedDate.toFormat("HH:mm");

      return { date: formattedDate, time: formattedTime };
    } catch (error) {
      console.error("Time formatting error:", error);
      return { date: "Invalid Date", time: "Invalid Time" };
    }
  };

  const groupedNews = news.reduce((acc, item) => {
    const { date } = formatTime(item.event_date, item.time);
    if (date !== "Invalid Date" && !ignoredCurrencies.includes(item.currency)) {
      if (!acc[date]) acc[date] = [];
      acc[date].push(item);
    }
    return acc;
  }, {});

  const impactColorMapping = {
    high: "#FF0000",
    medium: "#FFA500",
    low: "#FFD700",
  };

  useEffect(() => {
    const updateIsPast = () => {
      const now = DateTime.now(); // Bruk nåværende tid
      setNews((prevNews) =>
        prevNews.map((item) => {
          const eventTime = DateTime.fromISO(`${item.event_date}T${item.time}`, {
            zone: "UTC",
          });
          const isPast = eventTime.plus({ seconds: 30 }) < now; // Bruk sanntid
          return { ...item, isPast };
        })
      );
    };
  
    const interval = setInterval(() => {
      updateIsPast(); // Oppdater isPast hvert sekund
    }, 1000);
  
    return () => clearInterval(interval); // Rydd opp ved unmount
  }, []);

  return (
    <Box       sx={{
      position: "relative", // Sørger for at knappens posisjon er relativ til denne containeren
      paddingBottom: "50px", // Gi litt luft til bunnen for knappen
    }}>
      <Box
        sx={{
          position: "sticky",
          top: -10,
          zIndex: 0,
          bgcolor: "background.paper",
          padding: "10px",
          boxShadow: 2,
          width: "100%", // Sørger for at den dekker hele bredden
        }}
      >
        <Grid container spacing={1} justifyContent="center">
          {["High", "Medium", "Low"].map((impact) => (
            <Grid item key={impact}>
              <Button
                variant={impactFilter === impact ? "contained" : "outlined"}
                onClick={() =>
                  setImpactFilter(impact === impactFilter ? "" : impact)
                }
                size="small"
                sx={{
                  color:
                    impact === "High"
                      ? "#FF0000"
                      : impact === "Medium"
                      ? "#FFA500"
                      : "#FFD700",
                  borderColor:
                    impact === "High"
                      ? "#FF0000"
                      : impact === "Medium"
                      ? "#FFA500"
                      : "#FFD700",
                  fontSize: "0.7rem",
                  padding: "2px 6px",
                  minWidth: "auto",
                  "&:hover": {
                    backgroundColor:
                      impact === "High"
                        ? "#FF0000"
                        : impact === "Medium"
                        ? "#FFA500"
                        : "#FFD700",
                    color: "white",
                  },
                }}
              >
                {impact} Impact
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>

      {Object.keys(groupedNews).map((date) => (
        <Box key={date} sx={{ mb: 5 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              mb: 2,
              textAlign: "left",
            }}
          >
            {DateTime.fromISO(date, { zone: "UTC" }).isValid
              ? DateTime.fromISO(date, { zone: "UTC" }).toFormat("MMMM dd")
              : "Invalid Date"}
          </Typography>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              mb: 2,
            }}
          >
            <Table size="small">
              <TableBody>
                {groupedNews[date]
                  .filter(
                    (row) =>
                      !impactFilter ||
                      row.impact.toLowerCase() === impactFilter.toLowerCase()
                  )
                  .map((row) => {
                    const isPastEvent = row.isPast;

                    return (
                      <TableRow
                        key={row.event_id}
                        ref={row.event_id === nextEventId ? nextEventRef : null}
                        sx={{
                          scrollMarginTop: '60px',
                          height: "36px",
                          backgroundColor: isPastEvent
                            ? "rgba(128, 128, 128, 0.2)"
                            : row.event_id === nextEventId
                            ? "rgba(255, 255, 0, 0.3)"
                            : "transparent",
                          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                          textDecoration: isPastEvent ? "line-through" : "none",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "0.7rem",
                            textAlign: "left",
                            padding: "0 8px",
                            width: "50px",
                          }}
                        >
                          {row.time !== "Invalid Time"
                            ? formatTime(row.event_date, row.time).time
                            : "N/A"}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "0.7rem",
                            textAlign: "left",
                            padding: "0 8px",
                            width: "60px",
                          }}
                        >
                          {row.currency === "All" ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <Public fontSize="small" />
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{
                                  fontSize: "0.65rem",
                                }}
                              >
                                GLOBAL
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <Box
                                component="img"
                                src={`https://flagcdn.com/w40/${currencyToCountryCode[
                                  row.currency
                                ]?.toLowerCase()}.png`}
                                alt={row.currency}
                                sx={{
                                  width: 16,
                                  height: 11,
                                }}
                              />
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{
                                  fontSize: "0.65rem",
                                }}
                              >
                                {row.currency}
                              </Typography>
                            </Box>
                          )}
                        </TableCell>

                        <TableCell
                          colSpan={3}
                          sx={{
                            fontSize: "0.7rem",
                            paddingLeft: 1,
                            textAlign: "left",
                          }}
                        >
                          {row.event}
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              width: 10,
                              height: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                impactColorMapping[row.impact] || "grey",
                              margin: "auto",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        
      ))}
    {/* Auto-Scroll Toggle Button 
      <Button
        variant="contained"
        onClick={() => setAutoScrollEnabled((prev) => !prev)}
        sx={{
          position: "sticky", // Plassering relativt til ForexNews-containeren
          bottom: 16, // Plassering fra bunnen av containeren
          right: 0, // Plassering fra høyre kant av containeren
          zIndex: 10, // Sørger for at knappen vises over innholdet
          backgroundColor: "primary.main",
        }}
      >
        {autoScrollEnabled ? "Disable Auto-Scroll" : "Enable Auto-Scroll"}
      </Button>*/}
    </Box>
    
  );
};

export default ForexNews;
