import React from "react";
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie"; // For håndtering av cookies
import { Button, Link, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CookieConsentBanner = () => {
  const handleDecline = () => {
    // Fjern ikke-essensielle cookies
    Cookies.remove("analytics"); // Eksempel for Google Analytics
    Cookies.remove("marketing"); // Eksempel for tredjeparts cookies
    Cookies.remove("preferences"); // Eksempel for preferanser

    // Deaktiver eventuelle tredjeparts-skripter
    console.log("Non-essential cookies have been declined.");
  };

  const handleAccept = () => {
    // Sett nødvendige cookies eller aktiver funksjonalitet
    Cookies.set("analytics", "enabled", { expires: 365 }); // Eksempel for Google Analytics
    Cookies.set("marketing", "enabled", { expires: 365 }); // Eksempel for markedsføring
    console.log("Cookie consent granted.");
  };

  const { t } = useTranslation(); // Hent oversettelsesfunksjonen

  return (
    <CookieConsent
      location="bottom"
      cookieName="userConsent"
      enableDeclineButton
      buttonText={t("accept")} // Hent tekst for "Godta"-knappen
      declineButtonText={t("decline")} // Hent tekst for "Avslå"-knappen
      style={{
        backgroundColor: "#2B373B",
        padding: "8px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "8px",
      }}
      buttonStyle={{
        backgroundColor: "#4caf50",
        color: "#fff",
        fontSize: "14px",
        borderRadius: "4px",
        padding: "4px 8px",
      }}
      declineButtonStyle={{
        backgroundColor: "#f44336",
        color: "#fff",
        fontSize: "14px",
        borderRadius: "4px",
        padding: "4px 8px",
      }}
      ButtonComponent={(props) => (
        <Button
          {...props}
          variant="contained"
          style={{ ...props.style, backgroundColor: props.style.backgroundColor }}
        >
          {props.children}
        </Button>
      )}
      onAccept={handleAccept}
      onDecline={handleDecline}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          flexWrap: "wrap",
          color: "#FFF",
        }}
      >
        <Typography variant="body2" sx={{ margin: 0, fontSize: "14px" }}>
           {t("cookieMessage")}
        </Typography>
      </Box>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
