
const rolePermissions = {
  [process.env.REACT_APP_FLOCKOWNER_ROLE_ID]: ['flockowner','flockadmin', 'flockmod', 'flocktech', 'signals', 'flocktrader'],
  [process.env.REACT_APP_FLOCKADMIN_ROLE_ID]: ['flockadmin','flockmod', 'flocktech'],
  [process.env.REACT_APP_FLOCKMOD_ROLE_ID]: ['flockmod','flocktech'],
  [process.env.REACT_APP_FLOCKTECH_ROLE_ID]: ['flocktech','signals','flocktrader'],
  [process.env.REACT_APP_SIGNALS_ROLE_ID]: ['signals'],
  [process.env.REACT_APP_FLOCKTRADER_ROLE_ID]: ['flocktrader'],
};
  
  export default rolePermissions;
  