import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import {  ShowChart, ArrowUpward, BarChart, ArrowDownward  } from '@mui/icons-material';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const MainDashboard = () => {
  const { t } = useTranslation(); // Henter oversettelser
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/signals/stats/all`)
      .then((res) => res.json())
      .then(setDashboardData)
      .catch((err) => console.error('Error fetching dashboard data:', err));
  }, []);

  if (!dashboardData) return <Typography>{t('signals.loading')}</Typography>;

  const { totalSignals, winRate, longPositions, shortPositions, winRateOverTime, signalComparison, topAssets } =
    dashboardData;

  return (
    <Box sx={{ padding: { xs: 2, md: 4 } }}>
      {/* Dashboard Summary Cards */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ position: 'relative', p: 2 }}>
            <Box sx={{ position: 'absolute', top: 8, right: 8, color: '#2196F3' }}>
              <ShowChart  sx={{ fontSize: 25 }} />
            </Box>
            <CardContent>
              <Typography>{t('signals.totalSignals')}</Typography>
              <Typography variant="h5"><CountUp end={totalSignals} duration={3} /></Typography>
              <Typography variant="body2" color="textSecondary">
              {t('signals.lastYear')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ position: 'relative', p: 2 }}>
            <Box sx={{ position: 'absolute', top: 8, right: 8, color: '#2196F3' }}>
              <BarChart  sx={{ fontSize: 25 }} />
            </Box>
            <CardContent>
              <Typography>{t('signals.winRate')}</Typography>
              <Typography variant="h5"><CountUp end={winRate} duration={3} decimals={2} separator=","/>%</Typography>
              <Typography variant="body2" color="textSecondary">
              {t('signals.allTime')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ position: 'relative', p: 2 }}>
            <Box sx={{ position: 'absolute', top: 8, right: 8, color: 'green' }}>
              <ArrowUpward sx={{ fontSize: 25, transform: 'rotate(45deg)' }} />
            </Box>
            <CardContent>
              <Typography>{t('signals.longPositions')}</Typography>
              <Typography variant="h5"><CountUp end={longPositions.count} duration={3} /></Typography>
              <Typography variant="body2" color="textSecondary">
                {longPositions.winRate}% {t('signals.winRate')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ position: 'relative', p: 2 }}>
            <Box sx={{ position: 'absolute', top: 8, right: 8, color: '#F44336' }}>
              <ArrowDownward sx={{ fontSize: 25, transform: 'rotate(-45deg)'}} />
            </Box>
            <CardContent>
              <Typography>{t('signals.shortPositions')}</Typography>
              <Typography variant="h5"><CountUp end={shortPositions.count} duration={3} /></Typography>
              <Typography variant="body2" color="textSecondary">
                {shortPositions.winRate}% {t('signals.winRate')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts Section */}
      <Box sx={{ mt: 4, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
      {/* Win Rate Over Time */}
      <Box
        sx={{
          flex: 1,
          overflowX: 'auto',
          maxWidth: '100%',
          
        }}
      >
        <Typography>{t('signals.winRateOverTime')}</Typography>
        <Line
          data={{
            labels: winRateOverTime.dates,
            datasets: [
              {
                label: 'Win Rate',
                data: winRateOverTime.values,
                borderColor: '#007FFF',
                backgroundColor: 'rgba(0,127,255,0.2)',
                tension: 0.4,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              x: {
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 6,
                },
              },
            },
          }}
        />
      </Box>
    
      {/* Signal Comparison */}
      <Box
        sx={{
          flex: 1,
          overflowX: 'auto',
          maxWidth: '100%',
          
        }}
      >
        <Typography>{t('signals.signalComparison')}</Typography>
        <Bar
          data={{
            labels: signalComparison.labels,
            datasets: [
              {
                label: 'Total Trades',
                data: signalComparison.totalTrades,
                backgroundColor: '#007FFF',
              },
              {
                label: 'Win Rate',
                data: signalComparison.winRate,
                backgroundColor: '#00FF7F',
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              x: {
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 6,
                },
              },
            },
          }}
        />
      </Box>
    </Box>

      {/* Top Performing Assets Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
        {t('signals.topAssets')}
        </Typography>
        <Grid container spacing={2}>
          {topAssets.map((asset) => (
            <Grid item xs={12} sm={6} md={4} key={asset.name}>
              <Card
                sx={{
                  backgroundColor: '#1E293B', // Darker card background
                  color: '#22C55E', // Green text color
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', fontSize: '1.2rem', mb: 1 }}
                  >
                    {asset.name}
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'white', fontSize: '1rem' }}>
                  {t('signals.winRate')}: <b>{asset.winRate}%</b>
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'gray', fontSize: '0.9rem' }}>
                  {t('signals.signals')}: {asset.signalCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MainDashboard;
