import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleIcon from "@mui/icons-material/People";
import HeroSection from "../components/HeroSection"; // Importer HeroSection
import { useTheme } from "@mui/material/styles";
import newsDeskImage from '../assets/newsdesk_screendump.png';
import AdCard from "../components/AdCard";
import { useTranslation } from "react-i18next";

const Home = () => {
    const theme = useTheme(); // Henter aktivt tema (light/dark)
    const { t } = useTranslation(); // Oversettelsesfunksjon

    useEffect(() => {
        
        // Dynamisk legg til TradingView-widgeten
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbols: [
            {
              proName: "BITSTAMP:BTCUSD",
              title: "Bitcoin",
            },
            {
              proName: "BITSTAMP:ETHUSD",
              title: "Ethereum",
            },
            {
              description: "Nas100",
              proName: "TRADENATION:USTEC",
            },
            {
              description: "Dow Jones",
              proName: "TRADENATION:US30",
            },
          ],
          showSymbolLogo: true,
          isTransparent: false,
          displayMode: "adaptive",
          colorTheme: "dark",
          locale: "en",
        });
        document.getElementById("tradingview-ticker-tape").appendChild(script);
      }, []);

  return (
    <Box>
      <HeroSection />
      <Box id="tradingview-ticker-tape" sx={{ padding: "0px 0px", mb: 4 }} />
      <Typography variant="h4" component="h2" sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}>
        {t("home.keyFeatures")}
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ px: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: "center", p: 2 }}>
            <TrendingUpIcon fontSize="large" color="primary" />
            <CardContent>
              <Typography variant="h5">{t("home.realTimeNews")}</Typography>
              <Typography variant="body2" color="text.secondary">
                {t("home.realTimeNewsDesc")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: "center", p: 2 }}>
            <AssessmentIcon fontSize="large" color="primary" />
            <CardContent>
              <Typography variant="h5">{t("home.marketAnalysis")}</Typography>
              <Typography variant="body2" color="text.secondary">
                {t("home.marketAnalysisDesc")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: "center", p: 2 }}>
            <PeopleIcon fontSize="large" color="primary" />
            <CardContent>
              <Typography variant="h5">{t("home.communityInsights")}</Typography>
              <Typography variant="body2" color="text.secondary">
                {t("home.communityInsightsDesc")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 8, px: 4, py: 6, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold' }}>
          {t("home.sneakPeek")}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {t("home.sneakPeekDesc")}
        </Typography>
        <Box sx={{ textAlign: 'center', width: { xs: '100%', sm: '70%', md: '60%' }, margin: '0 auto' }}>
          <img
            src={newsDeskImage}
            alt="NewsDesk Screenshot"
            style={{
              width: '100%',
              borderRadius: '8px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => (window.location.href = '/signup')}
        >
          {t("home.getAccess")}
        </Button>
      </Box>
      <AdCard rotationInterval={7000} />
      <Box sx={{ mt: 8, px: 4, py: 6, backgroundColor: "#1e90ff", color: "white", textAlign: "center" }}>
        <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold" }}>
          {t("home.testimonials")}
        </Typography>
        <Typography variant="body1" sx={{ fontStyle: "italic", mb: 2 }}>
          {t("home.testimonial1")}
        </Typography>
        <Typography variant="body1" sx={{ fontStyle: "italic" }}>
          {t("home.testimonial2")}
        </Typography>
      </Box>
      <Box sx={{ mt: 8, px: 4, py: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}>
          {t("home.whyChooseUs")}
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {t("home.accurateSignals")}
              </Typography>
              <Typography>{t("home.accurateSignalsDesc")}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {t("home.realTimeUpdates")}
              </Typography>
              <Typography>{t("home.realTimeUpdatesDesc")}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {t("home.communitySupport")}
              </Typography>
              <Typography>{t("home.communitySupportDesc")}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="center" sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t("home.cta")}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => (window.location.href = "/signup")}
        >
          {t("home.joinUs")}
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
