import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Grid,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const currencyToCountryCode = {
  USD: 'us',
  EUR: 'eu',
  JPY: 'jp',
  GBP: 'gb',
  AUD: 'au',
  CAD: 'ca',
  CHF: 'ch',
  CNY: 'cn',
  NOK: 'no',
  NZD: 'nz',
};

const ForexNewsCalendar = () => {
  const { t } = useTranslation(); // Oversettelsesfunksjon
  const [news, setNews] = useState([]);
  const [filter, setFilter] = useState('today');
  const [impactFilter, setImpactFilter] = useState('');
  const [nextEventId, setNextEventId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/news/forex-calendar?filter=${filter}&impact=${impactFilter || ''}`;
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setNews(data);

        const now = DateTime.now();
        const nextEvent = data.find((item) => {
          const eventTime = DateTime.fromISO(`${item.event_date}T${item.time}`, { zone: 'UTC' });
          return eventTime > now;
        });
        setNextEventId(nextEvent?.event_id || null);
      } catch (error) {
        console.error('Error fetching forex news:', error);
      }
    };

    fetchData();
  }, [filter, impactFilter]);

  const formatTime = (date, time) => {
    if (!date || !time) return t('forexCalendar.table.time');
    const sourceTimeZone = 'UTC';
    const targetTimeZone =
      localStorage.getItem('timezone') === 'us'
        ? 'America/New_York'
        : 'Europe/Oslo';

    try {
      const eventDateTime = DateTime.fromISO(`${date}T${time}`, { zone: sourceTimeZone }).setZone(targetTimeZone);
      return eventDateTime.toFormat(localStorage.getItem('timezone') === 'us' ? 'hh:mm a' : 'HH:mm');
    } catch {
      return t('forexCalendar.table.time');
    }
  };

  const groupedNews = news.reduce((acc, item) => {
    const date = item.event_date;
    if (!acc[date]) acc[date] = [];
    acc[date].push(item);
    return acc;
  }, {});

  const impactColorMapping = {
    high: '#FF0000',
    medium: '#FFA500',
    low: '#FFD700',
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
        {t('forexCalendar.title')}
      </Typography>

      {/* Time Filter Buttons */}
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
        {['today', 'tomorrow', 'week', 'month'].map((timeFilter) => (
          <Grid item key={timeFilter}>
            <Button
              variant={filter === timeFilter ? 'contained' : 'outlined'}
              onClick={() => setFilter(timeFilter)}
              sx={{ textTransform: 'capitalize' }}
            >
              {t(`forexCalendar.filters.${timeFilter}`)}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Impact Filter Buttons */}
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
        {['high', 'medium', 'low'].map((impact) => (
          <Grid item key={impact}>
            <Button
              variant={impactFilter === impact ? 'contained' : 'outlined'}
              onClick={() => setImpactFilter(impact === impactFilter ? '' : impact)}
              sx={{
                color: impactColorMapping[impact],
                borderColor: impactColorMapping[impact],
                '&:hover': {
                  backgroundColor: impactColorMapping[impact],
                  color: 'white',
                },
              }}
            >
              {t(`forexCalendar.impactLevels.${impact}`)}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Grouped News by Date */}
      {Object.keys(groupedNews).length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
          {t('forexCalendar.noData')}
        </Typography>
      ) : (
        Object.keys(groupedNews).map((date) => (
          <Box key={date} sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, textTransform: 'capitalize' }}>
              {DateTime.fromISO(date).toFormat('MMMM dd')}
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  {groupedNews[date].map((row) => {
                    const isPastEvent =
                      DateTime.fromISO(`${row.event_date}T${row.time}`, { zone: 'UTC' }) < DateTime.now();

                    return (
                      <TableRow
                        key={row.event_id}
                        sx={{
                          backgroundColor: isPastEvent
                            ? 'rgba(128, 128, 128, 0.2)'
                            : row.event_id === nextEventId
                            ? 'rgba(255, 255, 0, 0.3)'
                            : 'transparent',
                          textDecoration: isPastEvent ? 'line-through' : 'none',
                        }}
                      >
                        <TableCell>{formatTime(row.event_date, row.time)}</TableCell>
                        <TableCell>
                        {currencyToCountryCode[row.currency] && (
                          <Box
                            component="img"
                            src={`https://flagcdn.com/w40/${currencyToCountryCode[
                              row.currency
                            ].toLowerCase()}.png`}
                            alt={row.currency}
                            sx={{ width: 16, height: 11, mr: 1 }}
                          />
                        )}
                        {row.currency}
                        </TableCell>
                        <TableCell>{row.event}</TableCell>
                        <TableCell>{row.forecast || t('forexCalendar.table.forecast')}</TableCell>
                        <TableCell>{row.previous || t('forexCalendar.table.previous')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))
      )}
    </Box>
  );
};

export default ForexNewsCalendar;
