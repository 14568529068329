import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

// Registrer nødvendige komponenter for Doughnut Chart
ChartJS.register(ArcElement, Tooltip, Legend);

const WeeklyStats = () => {
  const { t } = useTranslation(); // Oversettelser
  const [weeklyData, setWeeklyData] = useState(null);
  const [timeframe, setTimeframe] = useState('week');
  const [currentDate, setCurrentDate] = useState(new Date());

  // Hent data basert på valgt tidsperiode
  useEffect(() => {
    const formattedDate = currentDate.toISOString().split('T')[0];
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/signals/stats/weekly?timeframe=${timeframe}&date=${formattedDate}`
    )
      .then((res) => res.json())
      .then(setWeeklyData)
      .catch((err) => console.error('Error fetching weekly stats:', err));
  }, [timeframe, currentDate]);

  // Navigasjon for dato
  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    if (timeframe === 'week') {
      newDate.setDate(newDate.getDate() - 7);
    } else if (timeframe === 'month') {
      newDate.setMonth(newDate.getMonth() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (timeframe === 'week') {
      newDate.setDate(newDate.getDate() + 7);
    } else if (timeframe === 'month') {
      newDate.setMonth(newDate.getMonth() + 1);
    }
    setCurrentDate(newDate);
  };

  if (!weeklyData) return <Typography>{t('loading')}</Typography>;

  const { summary, providers } = weeklyData;

   // Beregn samlet oversikt for alle providers
   const overallSummary = providers.reduce(
    (acc, provider) => {
      acc.TP1 += provider.TP1 || 0;
      acc.TP2 += provider.TP2 || 0;
      acc.TP3 += provider.TP3 || 0;
      acc.TP4 += provider.TP4 || 0;
      acc.TP5 += provider.TP5 || 0;
      acc.stopLoss += provider.stopLoss || 0;
      acc.totalSignals += provider.totalSignals || 0;
      return acc;
    },
    { TP1: 0, TP2: 0, TP3: 0, TP4: 0, TP5: 0, stopLoss: 0, totalSignals: 0 }
  );

  const getChartData = (data) => ({
    labels: [t('signals.stats.tp1'), t('signals.stats.tp2'), t('signals.stats.tp3'), t('signals.stats.tp4'), t('signals.stats.tp5'), t('signals.stats.sl')],
    datasets: [
      {
        data: [data.TP1, data.TP2, data.TP3, data.TP4, data.TP5, data.stopLoss],
        backgroundColor: ['#4CAF50', '#8BC34A', '#CDDC39', '#FFC107', '#FF5722', '#F44336'],
      },
    ],
  });

  return (
    <Box sx={{ padding: { xs: 2, md: 4 }, bgcolor: 'background.default', minHeight: '100vh' }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" fontWeight="bold">
        {t('signals.stats.header')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            onClick={() => setTimeframe('week')}
            variant={timeframe === 'week' ? 'contained' : 'outlined'}
          >
          {t('signals.stats.weekly')}
          </Button>
          <Button
            onClick={() => setTimeframe('month')}
            variant={timeframe === 'month' ? 'contained' : 'outlined'}
          >
          {t('signals.stats.monthly')}
          </Button>
          <Button onClick={handlePrevious}>Previous</Button>
          <Typography>
            {timeframe === 'week'
              ? `${t('signals.stats.week')} ${getWeekNumber(currentDate)}, ${currentDate.getFullYear()}`
              : `${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}`}
          </Typography>
          <Button onClick={handleNext}>{t('signals.stats.next')}</Button>
        </Box>
      </Box>

      {/* Overall Performance */}
{/* Overall Performance */}
<Grid container spacing={4}>
  <Grid item xs={12}>
    <Card sx={{ padding: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          {/* Left: Summary */}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              {t('signals.stats.summary')}
              </Typography>
              <Typography
                variant="h8"
                fontWeight="bold"
                color="textSecondary"
                sx={{ mb: 1 }}
              >
              {t('signals.stats.total_signals')}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                sx={{ mb: 2 }}
              >
                {overallSummary.totalSignals}
              </Typography>
              <Typography
                variant="h8"
                fontWeight="bold"
                color="textSecondary"
                sx={{ mb: 1 }}
              >
              {t('signals.stats.win_rate')}
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ mb: 2 }}
              >
                {summary.winRate}%
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                variant="h7"
                fontWeight="bold"
                sx={{ mb: 1 }}
              >
              {t('signals.stats.position_types')}
              </Typography>
              <Box sx={{ display: 'flex', gap: '26px' }}>
                <Box sx={{ marginRight: 2 }}>
                  <Typography variant="h8" fontWeight="bold" color="textSecondary">
                  {t('signals.stats.long')}
                  </Typography>
                  <Typography variant="h5">
                    {summary.longSignals}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                  {t('signals.stats.win_rate')}: {summary.longWinRate}%
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h8" fontWeight="bold" color="textSecondary">
                  {t('signals.stats.short')}
                  </Typography>
                  <Typography variant="h5">
                    {summary.shortSignals}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                  {t('signals.stats.win_rate')}: {summary.shortWinRate}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* Right: Doughnut Chart */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
            {t('signals.stats.take_profit_distribution')}
            </Typography>
            <Box sx={{ height: 200 }}>
              <Doughnut
                data={getChartData(overallSummary)}
                options={{
                  responsive: true,
                  plugins: {
                    legend: { position: 'bottom' },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>



        {/* Signal Providers */}
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
          {t('signals.stats.signal_providers')}
          </Typography>
          <Grid container spacing={4}>
            {providers.map((provider) => (
              <Grid item xs={12} md={6} lg={4} key={provider.group_id}>
                <Card sx={{ padding: 2 }}>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      {/* Left Side: Doughnut Chart */}
                      <Grid item xs={12} md={5}>
                        <Box>
                        
                          <Typography variant="subtitle1" fontWeight="bold">
                            {provider.group_name}
                          </Typography>
                          <Typography variant="subtitle2" fontWeight="bold">
                          {t('signals.stats.take_profit_distribution')}
                        </Typography>
                          <Box sx={{ height: 150 }}>
                            <Doughnut
                              data={getChartData(provider)}
                              options={{
                                maintainAspectRatio: false,
                                plugins: { legend: { display: false } },
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      {/* Right Side: Statistics */}
                      <Grid item xs={12} md={7}>
                      <Box>
                      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
                      {t('signals.stats.position_types')}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                          {t('signals.stats.long_positions')}
                          </Typography>
                          <Typography variant="h5" fontWeight="bold">
                          {provider.longPositions}
                        </Typography>
                          <Typography variant="body2" color="textSecondary">
                          {t('signals.stats.win_rate')} {provider.longPositionsWinRate || 0}%
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" color="textSecondary">
                          {t('signals.stats.short_positions')}
                          </Typography>
                          <Typography variant="h5" fontWeight="bold">
                          {provider.shortPositions}
                        </Typography>
                          <Typography variant="body2" color="textSecondary">
                          {t('signals.stats.win_rate')}: {provider.shortPositionsWinRate || 0}%
                          </Typography>
                        </Box>
                      </Box>
                          <Typography variant="subtitle1" fontWeight="bold" mt={2}>
                          {t('signals.stats.take_profit_levels')}
                          </Typography>
                          {['TP1', 'TP2', 'TP3'].map((label, index) => (
                            <Typography key={label} fontSize="0.875rem">
                              {label}: {provider[`TP${index + 1}`]}
                            </Typography>
                          ))}
                          <Typography fontSize="0.875rem">
                          {t('signals.stats.stop_loss')}: {provider.stopLoss}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

// Helper function to calculate the week number
function getWeekNumber(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export default WeeklyStats;
