import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const { t } = useTranslation(); // Henter oversettelser

  const handleDiscordLogin = () => {
    // Send brukeren til backend-ruten for Discord OAuth
    const loginWindow = window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/discord`,
      'Discord Login',
      'width=500,height=600'
    );
    const checkPopupClosed = setInterval(() => {
      if (loginWindow.closed) {
        clearInterval(checkPopupClosed);
      }
    }, 1000);
  };

  return (
    <Box textAlign="center" sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t('signup.title')}
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        {t('signup.description')}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleDiscordLogin}>
        {t('signup.button')}
      </Button>
    </Box>
  );
};

export default SignUp;
