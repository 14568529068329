import React, { useState } from 'react';
import { Box, Typography, ToggleButtonGroup, ToggleButton, CircularProgress, Container, Button, Stack } from '@mui/material';
import MainDashboard from './MainDashboard'; // Dashboard with total stats
import WeeklyStats from './WeeklyStast'; // Weekly Stats Component
import { useUserData } from '../../auth/UserData';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const [view, setView] = useState('dashboard'); // Manage navigation between views
  const { isLoading } = useUserData();
  const { t } = useTranslation(); // Henter oversettelser

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      {/* Teaser Section */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography
          variant="h3"
          fontWeight="bold"
          gutterBottom
          sx={{
            fontSize: {
              xs: '1.5rem', // Fontstørrelse for små skjermer
              sm: '2.5rem', // Fontstørrelse for mellomstore skjermer
              md: '3rem', // Fontstørrelse for store skjermer
            },
          }}
        >
          {t('signals.teaser.title')}
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          {t('signals.teaser.description')}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => window.open('https://discord.com/invite/sQjPufbufK', '_blank', 'noopener,noreferrer')}
        >
          {t('signals.teaser.joinNow')}
        </Button>
      </Box>

      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          gutterBottom
          sx={{
            fontSize: {
              xs: '1.5rem', // Fontstørrelse for små skjermer
              sm: '2.5rem', // Fontstørrelse for mellomstore skjermer
              md: '2.5rem', // Fontstørrelse for store skjermer
            },
          }}
        >
          {t('signals.header')}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{ width: '100%', maxWidth: { xs: '100%', sm: 'auto' }, justifyContent: { sm: 'flex-end' } }}
        >
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(e, val) => val && setView(val)}
            sx={{
              width: { xs: '100%', sm: 'auto' },
              display: 'flex',
              justifyContent: { xs: 'space-between', sm: 'center' },
              gap: { xs: 1, sm: 0 },
            }}
          >
            <ToggleButton value="dashboard" sx={{ flex: 1, minWidth: 'auto' }}>
              {t('signals.overview')}
            </ToggleButton>
            <ToggleButton value="weekly" sx={{ flex: 1, minWidth: 'auto' }}>
              {t('signals.weeklyStats')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>

      {/* Render View Based on Selection */}
      {view === 'dashboard' && <MainDashboard />}
      {view === 'weekly' && <WeeklyStats />}
    </Box>
  );
};

export default Dashboard;
